jQuery(document).ready(function($) {
    $('.menu-item-type-search-icon').on('click', function(event) {
        event.preventDefault();
        $(this).hide();
        $(this).next().removeClass('d-none');
    });

    // Mobile dropdown management
    $('.nav-item-parent > a').on('click', function(event) {
        if($(this).next().hasClass('dropdown-menu')) {
            if($(window).width() < 977) {
                event.preventDefault();
                if($(this).parent().hasClass('open')) {
                    return window.location = $(this).attr('href');
                }
                $('.nav-item-parent').removeClass('open');
                $(this).parent().addClass('open');
            }
        }
    });
});