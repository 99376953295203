jQuery(document).ready(function($) {
    "use strict"

    $("#back-to-top").click(function() {
        $("html, body").animate({ scrollTop: 0 }, 1000);
    });

    $(window).scroll(function() {
        if (isScrolledIntoView('#page-top')) {
			$("#back-to-top").stop().fadeOut('fast');
        } else {
        	$("#back-to-top").stop().fadeIn('fast');
        }
    });
    
    function isScrolledIntoView(elem) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();

        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(elem).height();

        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }
});
