jQuery(document).ready(function($) {
    "use strict"
    
    $('[data-toggle="tooltip"]').tooltip();

    $('.post-share-link').on('click', function() {
        if ($(this).hasClass('facebook')) {
            window.open('http://www.facebook.com/sharer.php?u=' + location.href, '_blank');
        }
        if ($(this).hasClass('twitter')) {
            window.open('https://twitter.com/intent/tweet?text=' + document.getElementsByTagName("title")[0].innerHTML + '&url=' + location.href, '_blank');
        }
        if ($(this).hasClass('google-plus')) {
            window.open('https://plus.google.com/share?url=' + location.href, '_blank');
        }
        if ($(this).hasClass('linkedin')) {
            window.open('https://www.linkedin.com/shareArticle&title=' + document.getElementsByTagName("title")[0].innerHTML + '&url=' + location.href, '_blank');
        }
        if ($(this).hasClass('email')) {
            window.open('mailto:?subject=' + document.getElementsByTagName("title")[0].innerHTML + '&body=' + location.href, '_self');
        }
        if ($(this).hasClass('copy')) {
            var temp = $("<input>");
            $("body").append(temp);
                temp.val(location.href).select();
            document.execCommand("copy");
            temp.remove();
            $('.tooltip .tooltip-inner').text('Copied');
        }
    });
});